<template>
    <div>
        <div class="relative h-full">
            <div class="flex flex-col max-w-xl mx-auto px-4 py-10">
                <div class="flex-1 border rounded border-gray-700 z-20">
                    <h3 class="flex-auto text-center text-white pt-16 mb-2 font-semibold text-base md:text-xl">{{ t('cancelled') }}</h3>
                    <p class="flex-auto text-center text-white px-8 md:px-16 text-sm md:text-base">{{ t('cancelled_explanation') }}</p>
                    <div class="flex-1 text-center text-white pt-10">
                        <router-link :to="{ name: 'dashboard' }" class="block w-full p-8 mt-10 rounded-md shadow-sm">
                            <button type="button" class="button w-full">
                                {{ t('go_home') }}
                            </button>
                        </router-link>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import useI18n from "@/modules/globalI18n";

export default {
    setup() {
        return useI18n({
            viewPrefix: "Payment"
        });
    },
}
</script>